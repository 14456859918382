import React from 'react'
import FooterNavigationLarge from './FooterNavigationLarge'

import Content from 'styles/objects/Content'
import { FooterContainer, CardsContainer, NavigationText } from './styles'

const Footer = () => {
  const currentYear = new Date().getFullYear() // Obtém o ano atual

  return (
    <FooterContainer id="footer">
      <Content>
        <FooterNavigationLarge />
        <CardsContainer>
          <NavigationText marginOnSmall>
            © Veloe {currentYear} todos direitos reservados
          </NavigationText>
        </CardsContainer>
      </Content>
    </FooterContainer>
  )
}

export default Footer
